import React, { useEffect, useState } from 'react';
import { Stack, Text, IconButton } from '@fluentui/react';
import { useNavigate, useLocation } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [currentPath, setCurrentPath] = useState(location.pathname);

  useEffect(() => {
    setCurrentPath(location.pathname);
  }, [location]);

  const getCurrentPageTitle = () => {
    switch (currentPath) {
      case '/':
        return 'Home';
      case '/projects':
        return 'Projects';
      case '/about':
        return 'About Me';
      case '/resume':
        return 'Resume';
      case '/goals':
        return 'Goals';
      default:
        return '';
    }
  };

  const handleEmailClick = () => {
    window.location.href = 'mailto:peytonslater2000@gmail.com';
  };

  return (
    <header className="header-bar">
      <Stack horizontal horizontalAlign="space-between" verticalAlign="center" className="header-content">
        <Text variant="xxLarge" className="header-title">Peyton's Website</Text>
        <Stack horizontal horizontalAlign="center" verticalAlign="center" className="nav-icons">
          <IconButton
            iconProps={{ iconName: 'Home' }}
            title="Home"
            ariaLabel="Home"
            className={`nav-button ${currentPath === '/' ? 'active-button' : ''}`}
            onClick={() => navigate('/')}
          />
          <IconButton
            iconProps={{ iconName: 'Build' }}
            title="Projects"
            ariaLabel="Projects"
            className={`nav-button ${currentPath === '/projects' ? 'active-button' : ''}`}
            onClick={() => navigate('/projects')}
          />
          <IconButton
            iconProps={{ iconName: 'Contact' }}
            title="About Me"
            ariaLabel="About Me"
            className={`nav-button ${currentPath === '/about' ? 'active-button' : ''}`}
            onClick={() => navigate('/about')}
          />
          <IconButton
            iconProps={{ iconName: 'Document' }}
            title="Resume"
            ariaLabel="Resume"
            className={`nav-button ${currentPath === '/resume' ? 'active-button' : ''}`}
            onClick={() => navigate('/resume')}
          />
          <IconButton
            iconProps={{ iconName: 'Bullseye' }}
            title="Goals"
            ariaLabel="Goals"
            className={`nav-button ${currentPath === '/goals' ? 'active-button' : ''}`}
            onClick={() => navigate('/goals')}
          />
          <IconButton
            iconProps={{ iconName: 'Mail' }}
            title="Contact Me"
            ariaLabel="Contact Me"
            className="nav-button"
            onClick={handleEmailClick}
          />
        </Stack>
                <Text variant="large" className="header-page-title" style={{ color: 'white' }}>
          {getCurrentPageTitle()}
        </Text>
      </Stack>
    </header>
  );
};

export default Header;
