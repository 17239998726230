// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resume-page {
    display: flex;
    justify-content: center;
    background-color: white;
  }
  
  .resume-content {
    width: calc(100% - 2in); /* Full width minus 2 inches for left and right margins */
    margin: 0 1in; /* 1 inch margin on left and right */
  }
  
  .resume-title {
    font-weight: bold;
    font-size: 32px;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .resume-viewer {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px;
  }
  
  .action-button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #0078d4;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .action-button:hover {
    background-color: #005a9e;
  }
  `, "",{"version":3,"sources":["webpack://./src/resume.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;EACzB;;EAEA;IACE,uBAAuB,EAAE,yDAAyD;IAClF,aAAa,EAAE,oCAAoC;EACrD;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,2CAA2C;IAC3C,mBAAmB;EACrB;;EAEA;IACE,2CAA2C;IAC3C,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,eAAe;IACf,yBAAyB;IACzB,YAAY;IACZ,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,sCAAsC;EACxC;;EAEA;IACE,yBAAyB;EAC3B","sourcesContent":[".resume-page {\n    display: flex;\n    justify-content: center;\n    background-color: white;\n  }\n  \n  .resume-content {\n    width: calc(100% - 2in); /* Full width minus 2 inches for left and right margins */\n    margin: 0 1in; /* 1 inch margin on left and right */\n  }\n  \n  .resume-title {\n    font-weight: bold;\n    font-size: 32px;\n    color: black;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n    margin-bottom: 20px;\n  }\n  \n  .resume-viewer {\n    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);\n    margin-bottom: 20px;\n  }\n  \n  .action-button {\n    padding: 10px 20px;\n    font-size: 16px;\n    background-color: #0078d4;\n    color: white;\n    border: none;\n    border-radius: 5px;\n    cursor: pointer;\n    transition: background-color 0.3s ease;\n  }\n  \n  .action-button:hover {\n    background-color: #005a9e;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
