import React from 'react';
import { Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import ProjectsPage from './ProjectsPage'; // Import ProjectsPage component
import AboutMe from './aboutMe'; // Import AboutMePage component
import Header from './Header';
import Resume from './resume'; // Import ResumePage component
import Goals from './goals'; // Import GoalsPage component

const App = () => (
  <>
    <Header themeOptions={[]} handleThemeChange={() => {}} />
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/projects" element={<ProjectsPage />} /> {/* Add route for ProjectsPage */}
      <Route path="/about" element={<AboutMe />}/>
      <Route path="/resume" element={<Resume />}/>
      <Route path="/goals" element={<Goals />}/>
    </Routes>
  </>
);

export default App;