import React, { useState } from 'react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Stack, Text, IconButton, DefaultButton, Image } from '@fluentui/react';
import { mergeStyles, AnimationStyles } from '@fluentui/react/lib/Styling';
import './main.css';
import './aboutMe.css';

const AboutMe = () => {
    return (
      <div className="about-page">
        <main className="about-content">
          <div className="description-box">
            <Text variant="xLarge" className="section-title">About Me</Text>
            <br />
            <Text variant="large" className="section-description">
              Height: 6'6"<br />
              Ethnicity: White<br />
              Gender: Male<br />
              Religion: Christian<br />
              Hobbies: Bodybuilding, Coding, Gaming
            </Text>
          </div>
  
          <div className="backstory-box">
            <Text variant="xLarge" className="section-title">Backstory</Text>
            <br />
            <Text variant="large" className="section-description">
              I grew up in Colorado, raised mainly by my single mother since the age of 10 after my father passed from liver failure. I have 6 siblings—5 brothers and 1 sister. I graduated high school in 2018 from Arapahoe High School and completed my BS in Computer Science at the University of Colorado Denver in July 2024. I have always been interested in video games, ranging from World of Warcraft to Call of Duty. I love RDR2 and Witcher 3, both of which I have completed 100%. In the summer of 2020, I picked up bodybuilding (weightlifting) and have enjoyed it ever since, going 6 days a week consistently for over 4 years.
            </Text>
          </div>
  
          <div className="inspiration-box">
            <Text variant="xLarge" className="section-title">Inspiration</Text>
            <br />
            <Text variant="large" className="section-description">
              I am mainly inspired by my mother, who stayed strong and raised 6 kids on her own. Her strength and determination, as she raised us, got a nursing degree, and worked full-time, is where my own strength comes from. Another role model is my oldest brother, Sheldon, who has consistently kept me focused and motivated, especially in my schoolwork and now in my job search for software engineering. He recently graduated from the same school and now works for Microsoft. Lastly, I draw inspiration from Jesus Christ. I try to focus on Him and His teachings throughout my life, knowing that any problem or struggle can be tackled through Him.
            </Text>
          </div>
        </main>
      </div>
    );
  };
  
  export default AboutMe;

