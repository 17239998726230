// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.goals-page {
    display: flex;
    justify-content: center;
    background-color: white;
  }
  
  .goals-content {
    width: calc(100% - 2in); /* Full width minus 2 inches for left and right margins */
    margin: 0 1in; /* 1 inch margin on left and right */
  }
  
  .goals-title {
    font-weight: bold;
    font-size: 32px;
    color: black;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    margin-bottom: 20px;
  }
  
  .goals-list {
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  .goals-list ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .goal-title {
    font-weight: bold;
    font-size: 24px;
    color: black;
    margin-bottom: 10px;
  }
  
  .goal-description {
    color: #666;
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
  }
  `, "",{"version":3,"sources":["webpack://./src/goals.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,uBAAuB;EACzB;;EAEA;IACE,uBAAuB,EAAE,yDAAyD;IAClF,aAAa,EAAE,oCAAoC;EACrD;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,2CAA2C;IAC3C,mBAAmB;EACrB;;EAEA;IACE,2CAA2C;IAC3C,aAAa;IACb,yBAAyB;IACzB,kBAAkB;EACpB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;IACjB,eAAe;IACf,YAAY;IACZ,mBAAmB;EACrB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB","sourcesContent":[".goals-page {\n    display: flex;\n    justify-content: center;\n    background-color: white;\n  }\n  \n  .goals-content {\n    width: calc(100% - 2in); /* Full width minus 2 inches for left and right margins */\n    margin: 0 1in; /* 1 inch margin on left and right */\n  }\n  \n  .goals-title {\n    font-weight: bold;\n    font-size: 32px;\n    color: black;\n    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);\n    margin-bottom: 20px;\n  }\n  \n  .goals-list {\n    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);\n    padding: 20px;\n    background-color: #f9f9f9;\n    border-radius: 8px;\n  }\n  \n  .goals-list ul {\n    list-style-type: disc;\n    padding-left: 20px;\n  }\n  \n  .goal-title {\n    font-weight: bold;\n    font-size: 24px;\n    color: black;\n    margin-bottom: 10px;\n  }\n  \n  .goal-description {\n    color: #666;\n    font-size: 16px;\n    line-height: 1.5;\n    margin-bottom: 20px;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
