import React, { useState, useRef, useEffect } from 'react';
import { Text, Pivot, PivotItem, Stack } from '@fluentui/react';
import './ProjectsPage.css'; // Import the CSS file for styling

// Import the video file
import practiceVideo from './assets/practicevideo.mp4';

const projects = [
  {
    id: 1,
    title: 'PracticeSmith',
    description: `
      Practice-Smith is a Plan Creation Software used for coaches to manage teams based on sport with plans, drills, and players. It provides a comprehensive solution for team management and planning.
      This was a continuation project of Senior Design where a team worked on it the previous year. I took over and went through the entire Software Design Process with two team members, doing documentation, design docs, client/team meetings, and finally coding.
      I coded the create plan page and overhauled it based on the requirements document and client feedback to create a more useful product. This involved creating drawings, activities, drills, and a lot of work into using the database for creation and getting the other pages to load and save.
      Work in Progress: http://147.182.228.52:2001
    `,
    techStack: ['HTML', 'Handlebars', 'Bootstrap', 'Node.js', 'Express.js', 'MySQL', 'JavaScript'],
    algorithmRelevance: `
      This project implements algorithms for optimal plan creation, leveraging efficient database queries and UI design to enhance user experience and relevance to sports team management.
    `,
  },
  {
    id: 2,
    title: 'MCTS Tic-Tac-Toe',
    description: `
      MCTS Tic-Tac-Toe is a project focused on developing a Tic-Tac-Toe game using the Monte Carlo Tree Search (MCTS) algorithm. Achieved 97% accuracy in predicting the best moves.
    `,
    techStack: ['Python', 'Pickle'],
    algorithmRelevance: `
      The project uses the Monte Carlo Tree Search algorithm to optimize game strategy, providing a significant challenge and demonstrating the effectiveness of MCTS in game AI development.
    `,
  },
  {
    id: 3,
    title: 'Food Classifier',
    description: `
      Developed a 250-class food classifier with 99% accuracy, utilizing advanced deep learning techniques and a custom CNN architecture.
    `,
    techStack: ['Python', 'PIL', 'Torch', 'Torchvision', 'Pandas', 'Scikit-learn', 'Matplotlib'],
    algorithmRelevance: `
      The custom CNN and ResNet18 architecture were key in achieving high accuracy in image classification, showcasing the power of deep learning in real-world applications.
    `,
  },
  {
    id: 4,
    title: 'Edge & Object Detection',
    description: `
      Developed an application for edge detection with six different algorithms and implemented object detection to identify unique objects within edge-detected images.
    `,
    techStack: ['Python', 'PIL', 'Torch', 'Torchvision', 'Pandas', 'Scikit-learn', 'Matplotlib'],
    algorithmRelevance: `
      The project emphasizes the application of edge detection algorithms and object detection in computer vision, highlighting the significance of image processing techniques.
    `,
  },
  {
    id: 5,
    title: 'Node Networking',
    description: `
      Worked with a class to create a node network using C socket programming and a custom protocol on Linux, developing a robust and efficient communication system.
    `,
    techStack: ['C', 'Socket Programming', 'Linux'],
    algorithmRelevance: `
      The project focused on developing a custom protocol for data communication, essential for understanding network programming and low-level system interaction.
    `,
  },
  {
    id: 6,
    title: 'Store Simulation',
    description: `
      Using Simpy and Python, developed a Store Simulation with data collection and numerical analysis, providing insights into store operations and customer behavior.
    `,
    techStack: ['Python', 'Simpy'],
    algorithmRelevance: `
      This simulation project applied queueing theory and discrete event simulation to model and analyze store operations, offering valuable insights into retail management.
    `,
  },
];

const iconNames = [
  "Trackers",
  "Waffle",
  "Relationship",
  "GenericScanFilled",
  "Dataflows",
  "ThisPC" // Replace with the actual simulation icon name from Fluent UI
];

const ProjectsPage = () => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = 0.05; // Set initial volume to 5%
    }
  }, []);

  return (
    <div className="projects-page">
      <main className="projects-content">
        <Pivot className="custom-pivot">
          {projects.map((project, index) => (
            <PivotItem
              key={project.id}
              itemIcon={iconNames[index] || "DefaultIcon"} // Use the icon from the array or a default icon if the index exceeds the array length
              itemKey={project.id.toString()}
              headerText={project.title} // Add the project title next to the icon
            >
              <div className="pivot-content">
                <Stack horizontal tokens={{ childrenGap: 10 }}>
                  <div className="description-box">
                    <Text variant="large" className="pivot-title">{project.title}</Text>
                    <br /> 
                    <Text variant="medium" className="pivot-description">{project.description}</Text>
                  </div>
                  <div className="info-boxes">
                    <div className="tech-stack">
                      <Text variant="medium" className="tech-stack-title">Tech Stack</Text>
                      <ul>
                        {project.techStack.map((tech, i) => (
                          <li key={i}>{tech}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="algorithm-box">
                      <Text variant="medium" className="algorithm-title">Algorithm / Relevance</Text>
                      <br /> 
                      <Text variant="small" className="algorithm-description">
                        {project.algorithmRelevance}
                      </Text>
                    </div>
                  </div>
                </Stack>
                {project.id === 1 && (
                  <div className="media-section">
                    <Text variant="large" className="media-title">PracticeSmith Presentation</Text>
                    <div className="pdf-container">
                      <iframe
                        src={require('./assets/practicesmithpresentation.pdf') + '#view=FitH'} // Fit to page width
                        width="100%"
                        height="600px"
                        title="PracticeSmith Presentation"
                        style={{ border: 'none' }}
                      />
                    </div>
                    <Text variant="large" className="media-title">PracticeSmith Video</Text>
                    <div className="video-container">
                      <video ref={videoRef} width="100%" height="600px" controls>
                        <source src={practiceVideo} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                )}
              </div>
            </PivotItem>
          ))}
        </Pivot>
      </main>
    </div>
  );
};

export default ProjectsPage;
