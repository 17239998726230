import React from 'react';
import { Text } from '@fluentui/react';
import './goals.css'; // Import the CSS file for styling

const Goals = () => {
  return (
    <div className="goals-page">
      <main className="goals-content">
        <Text variant="xLarge" className="goals-title">Goals</Text>
        <div className="goals-list">
          <ul>
            <li>
              <Text variant="large" className="goal-title">Develop My Own Video Game</Text>
              <br /> 
              <Text variant="medium" className="goal-description">
                Embarking on the exciting journey of creating my own immersive and captivating video game.
              </Text>
            </li>
            <li>
              <Text variant="large" className="goal-title">Contribute to Booming A.I. Technologies</Text>
              <br /> 
              <Text variant="medium" className="goal-description">
                Exploring the forefront of artificial intelligence, aiming to contribute and shape the future of this rapidly evolving field.
              </Text>
            </li>
            <li>
              <Text variant="large" className="goal-title">Help with Web Development Projects</Text>
              <br /> 
              <Text variant="medium" className="goal-description">
                Utilizing my skills to actively participate in and enhance various web development projects, fostering innovation and efficiency.
              </Text>
            </li>
            <li>
              <Text variant="large" className="goal-title">Become a Father Someday</Text>
              <br /> 
              <Text variant="medium" className="goal-description">
                Aspiring to embrace the joy and responsibilities of fatherhood, creating a nurturing environment for the next generation.
              </Text>
            </li>
            <li>
              <Text variant="large" className="goal-title">Live My Life the Way of Jesus Christ</Text>
              <br /> 
              <Text variant="medium" className="goal-description">
                Guiding my life with the principles of love, compassion, and kindness, following the teachings of Jesus Christ as a source of inspiration and purpose.
              </Text>
            </li>
          </ul>
        </div>
      </main>
    </div>
  );
};

export default Goals;
