import React, { useState } from 'react';
import { initializeIcons } from '@fluentui/font-icons-mdl2';
import { Stack, Text, IconButton, DefaultButton, Image } from '@fluentui/react';
import { mergeStyles, AnimationStyles } from '@fluentui/react/lib/Styling';
import PeytonImage from './assets/PeytonImage.jpg'; // Import the image
import { ImageFit } from '@fluentui/react/lib/Image';
import './main.css';

initializeIcons();

const themeOptions = [
  { key: 'light', text: 'Light' },
  { key: 'dark', text: 'Dark' },
];

const HomePage = () => {
  const [theme, setTheme] = useState('light');

  const handleThemeChange = (key) => {
    setTheme(key);
  };

  return (
    <div className={`homepage ${theme}`}>
      <main className="content">
        <Stack horizontalAlign="center" tokens={{ childrenGap: 20 }}>
          <Image
            src={PeytonImage}
            alt="Peyton Slater"
            className="profile-picture"
            width={300}
            height={300}
            imageFit={ImageFit.contain}
          />
          <Text variant="xxLarge" className="intro-title">Hello there! I'm Peyton Slater</Text>
          <Text variant="large" className="intro-description">
            A passionate Bachelor of Computer Science student at CU Denver. I'm thrilled to welcome you to my digital hub where I showcase the exciting journey of my academic and personal exploration.
          </Text>
          <Stack tokens={{ childrenGap: 30 }} className="info-section" horizontalAlign="start">
            <Text variant="xxLarge" className="info-title">What to Expect:</Text>
            <Text variant="large" className={mergeStyles(AnimationStyles.fadeIn400, 'info-text')}>
              <strong>Projects:</strong> Dive into a collection of both personal and class projects that I've poured my heart and coding skills into. From innovative solutions to coding challenges to creative experiments, there's a bit of everything here.
            </Text>
            <Text variant="large" className={mergeStyles(AnimationStyles.fadeIn400, 'info-text')}>
              <strong>About Me:</strong> Curious about the person behind the code? Click on the 'About Me' tab to get a closer look at who I am, my aspirations, and the story behind my coding adventures.
            </Text>
            <Text variant="large" className={mergeStyles(AnimationStyles.fadeIn400, 'info-text')}>
              <strong>Resume:</strong> Explore the 'Resume' tab for a concise snapshot of my academic journey, work experience, and the skills that make me tick.
            </Text>
            <Text variant="large" className={mergeStyles(AnimationStyles.fadeIn400, 'info-text')}>
              <strong>Goals:</strong> Explore my aspirations and the roadmap outlined on the Goals page. As I share my vision for the future, join me in the pursuit of impactful contributions to various realms within the vast landscape of Computer Science.
            </Text>
            <Text variant="large" className={mergeStyles(AnimationStyles.fadeIn400, 'info-text')}>
              <strong>Contact Me:</strong> Head over to the 'Contact Me' tab to get in touch with me for any queries, opportunities, or discussions.
            </Text>
            <Text variant="large" className={mergeStyles(AnimationStyles.fadeIn400, 'info-text')}>
              Have Fun! 
            </Text>
          </Stack>
        </Stack>
      </main>
    </div>
  );
};

export default HomePage;
