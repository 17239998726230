import React from 'react';
import { Text } from '@fluentui/react';
import './resume.css';
import resumePdf from './assets/Slater_Peyton_Resume.pdf';

const Resume = () => {
  const downloadResume = () => {
    const link = document.createElement('a');
    link.href = resumePdf;
    link.download = 'Slater_Peyton_Resume.pdf';
    link.click();
  };

  return (
    <div className="resume-page">
      <main className="resume-content">
        <Text variant="xLarge" className="resume-title">Resume</Text>

        <div className="resume-viewer">
          <iframe
            src={resumePdf}
            title="Resume"
            width="100%"
            height="800px"
            style={{ border: 'none' }}
          ></iframe>
        </div>
        <button onClick={downloadResume}>Download Resume</button>
      </main>
    </div>
  );
};

export default Resume;
